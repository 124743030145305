import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled from 'styled-components'

const OtherServices = styled.div`
  background: #10101010;
  h4 {
    font-size: ${(props) => props.theme.font.size.xxl};
    font-family: ${(props) => props.theme.font.family.secondary};
  }
  @media screen and (max-width: 576px) {
    font-size: ${(props) => props.theme.font.size.l};
  }
`
const Service = styled.div`
  padding: 0 8px 0 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  background: white;
  border: 1px solid ${(props) => props.theme.color.face.main};
  text-align: center;
  width: 200px;
  color: ${(props) => props.theme.color.text.main};
  font-size: ${(props) => props.theme.font.size.sm};
  line-height: ${(props) => props.theme.font.size.sm};
  font-family: ${(props) => props.theme.font.family.main};
  opacity: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Container = styled.div`
  @media (min-width: 1200px) {
    max-width: 1350px;
  }
`

const OtherService = ({ className, id}) => {
  const {
    services: {
      nodes: services
    }
  } = useStaticQuery(graphql`{
    services: allWordpressPage(filter: {wordpress_parent: {eq: 117}}) {
      nodes {
        ...ServicesFrag
      }
    }
  }`)

  const filteredServices = services.filter((e) => e.wordpress_id !== id)

  return (
    <OtherServices className={`py-5 mt-5 ${className ? `${className}` : ``}`}>
      <div className="container my-4">
        <h4 className="text-center">OVERIGE DIENSTEN</h4>
      </div>
      <Container className="container d-flex flex-wrap justify-content-center mb-4">
        {filteredServices.map((service) => {
          return (
            <CustomLink to={service.path}>
              <Service key={service.wordpress_id} className="py-3 m-2">
                {service.title}
              </Service>
            </CustomLink>
          )
        })}
      </Container>
    </OtherServices>
  )
}

export default OtherService