/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroDefault from 'components/elements/HeroDefault'
import Products from 'components/elements/Products'
import ProductTabs from 'components/elements/ProductTabs'
import OtherService from 'components/elements/OtherService'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

// Third Party
import styled from 'styled-components'

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {

  return (
    <Layout activePage={'/over-vmt/'}>
      <SEO yoast={yoast} path={path} article />

      <HeroDefault fields={acf.banner} />

      <section className="mt-n5">
        <div className="container mb-5">
          <div className="row pb-5">
            <div className="col-12 d-flex flex-wrap justify-content-end">
              <ProductTabs />
            </div>    
          </div>
        </div>
      </section>

     

      <Products catId={[3]} name="Plaatwerk" />

      <Products catId={[4]} name="Constructie"/>

      <Products catId={[5]} name="Verspaning" />
    
      <OtherService />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...BewerkingenPage
    }
  }
`

export default PageTemplate
