import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const Service = styled.div`
  padding: 0 8px 0 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  background: white;
  border: 1px solid ${(props) => props.theme.color.face.main};
  text-align: center;
  width: 200px;
  color: ${(props) => props.theme.color.text.main};
  font-size: ${(props) => props.theme.font.size.sm};
  line-height: ${(props) => props.theme.font.size.sm};
  font-family: ${(props) => props.theme.font.family.main};
  opacity: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`


const ProductTabs = () => {
  const { tabs } = useStaticQuery(graphql`
  {
    tabs: allWordpressCategory(filter: {wordpress_id: {ne: 1}}) {
      edges {
        node {
          name
          wpId: wordpress_id
        }
      }
    }
  }
`)

  return (
    <>
    {tabs.edges.map(({ node: { name, wpId } }, index) => (
      <a href={`#${name}`}>
        <Service key={index} className="py-3 m-2">
          {name}
        </Service>
      </a>
    ))}
    </>
  )
}
export default ProductTabs