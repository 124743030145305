import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

// Elements
import TitleDefault from 'components/elements/TitleDefault'
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'

const ProductBox = styled.div``

const InnerBox = styled.div`
  border: 1px solid ${props => props.theme.color.face.main};
`

const Title = styled.h4`
  font-size: ${props => props.theme.font.size.xsm};
  font-weight: ${props => props.theme.font.weight.l};
`

const Content = styled(ParseContent)`
  & p {
    font-size: ${props => props.theme.font.size.sm};
    text-transform: uppercase;
  }
`

const Products = ({ catId, name }) => {
  const { productList } = useStaticQuery(graphql`
  {
    productList: allWordpressWpProducten {
      edges {
        node {
          acf {
            overview {
              image {
                localFile {
                  publicURL
                }
              }
              title
              description
            }
          }
          categories {
            wpId: wordpress_id
            name
          }
        }
      }
    }
  }
`)

  let productPosts = []
  let response

    productPosts = productList.edges.filter(({ node: { categories } }) => {
    
      if(catId.length > 0) {
        response = true

        categories.forEach(category => {
          if(catId.filter((c) => c === category.wpId).length === 0) {
            response = false
          }
        })
      }
      return response
    })

  return (
    <section id={name}>
      <div className="container mb-5">
        <div className="row py-5">
          <div className="col-12">
              <TitleDefault left className="ml-0" title={name} />
          </div>
        </div>
        <div className="row pb-5">
          {productPosts.map(({ node: { acf, categories } }, index) => (
            <ProductBox className="col-lg-4 col-md-6 mb-4" key={index}>
              <InnerBox>
                <Image src={acf.overview.image} />
                <div className="px-3">
                  <Title className="mb-3">{acf.overview.title}</Title>
                  <Content content={acf.overview.description} />
                </div>
              </InnerBox>
            </ProductBox>
          ))} 
        </div>
      </div>
    </section>
  )

}

export default Products